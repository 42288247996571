import {Langage} from "./language";

const myLang = 'french'
const getText = (key) => {
    return Langage[key][myLang]
}

export const needsBot = {
     "_BEFORE": [
         {
             "id": 1,
             "question": getText('have_alergy'),
             "choices": [
                 {"text": getText('yes'), "value": true, "next": 2},
                 {"text": getText('nope'), "value": false, "next": -1}
             ],
             "widget": "multiChoices",
             "brief": getText('brief_allergie')
         },
         {
             "id": 2,
             "question": getText('with_one'),
             "brief": getText('brief_allergie')
         }
     ],
     "RIDES": [
         {
             "id": 3,
             "question": getText('rides_indique'),
             "choices": [
                 getText('eye_circle'), getText('in_front'), getText('bouche_circle'), getText('rides_sourc'), getText('partout')
             ],
             "brief": getText('brief_rides'),
             "widget": "multiChoices"
         },
         {
             "id": 4,
             "question": getText('want_relachement'),
             "choices": [
                 {"text": getText('yes'), "value": getText('i_want_relachement')},
                 {"text": getText('nope'), "value": ""}
             ],
             "brief": "",
             "widget": "multiChoices"
         }
     ],
     "SENSIBILITE": [
         {
             "id": 5,
             "question": getText('eczema_concern'),
             "choices": [
                 {"text": getText('yes'), "value": getText('i_m_eczema_concern')},
                 {"text": getText('nope'), "value": ""}
             ],
             "brief": "",
             "widget": "multiChoices"
         }
     ],
     "BRILLANCE": [
         {
             "id": 6,
             "question": getText('product_correspondent'),
             "choices": [
                 getText('requilibrant'), getText('none_sulfate'), getText('purifiant'), getText('matifiant')
             ],
             "brief": getText('brief_product_correspondent'),
             "multichoices": true,
             "widget": "multiChoices"
         }
     ],
     "BOUTONS": [
         {
             "id": 7,
             "question": getText('boutons_indique'),
             "choices": [
                 getText('partout'), getText('zone_t'), getText('in_joues'), getText('locale')
             ],
             "brief": getText('brief_boutons_found'),
             "widget": "multiChoices"
         },
         {
             "id": 8,
             "question": getText('see_boutons'),
             "choices": [
                 getText('permanence'), getText('episod')
             ],
             "brief": getText('brief_boutons_present'),
             "widget": "multiChoices"
         }
     ],
     "TACHES": [
         {
             "id": 9,
             "question": getText('remove_taches'),
             "choices": [
                 getText('rouss_taches'), getText('old_taches'), getText('vin_taches'), getText('birth_taches'), getText('pregnant_taches')
             ],
             "brief": getText('brief_taches'),
             "multichoices": true,
             "widget": "multiChoices"
         }
     ],
     "HYDRATATION": [
         {
             "id": 10,
             "question": getText('hydrate'),
             "choices": [
                 getText('teint_terne'), getText('stries'), getText('souplesse'), getText('traillements'), getText('demangeaisons')
             ],
             "brief": getText('brief_hydrate'),
             "multichoices": true,
             "widget": "multiChoices"
         }
     ],
     "GRAIN DE PEAU": [
         {
             "id": 11,
             "question": getText('visible_pores'),
             "choices": [
                 {"text": getText('never'), "value": ""},
                 {"text": getText('some_time'), "value": getText('pores_light')},
                 {"text": getText('always'), "value": getText('pores_exposed')},
                 {"text": getText('day_period'), "value": getText('pores_sometimes')}
             ],
             "brief": "",
             "widget": "multiChoices"
         },
         {
             "id": 12,
             "question":getText('cicatrice_on_face'),
             "choices": [
                 {"text": getText('none'), "value": ""},
                 {"text": getText('some'), "value": getText('pores_scars')},
                 {"text": getText('a_lot'), "value": getText('pores_scars_alot')}
             ],
             "brief": "",
             "widget": "multiChoices"
         }
     ],
     "ECLAT": [
         {
             "id": 13,
             "question": getText('pollue_city'),
             "choices": [
                 getText('pollue_a_lot'), getText('pollue_average'), getText('pollue_little'), getText('none_pollue')
             ],
             "brief": getText('brief_eclat_env'),
             "widget": "multiChoices"
         },
         {
             "id": 14,
             "question": getText('product_interess'),
             "choices": [
                 getText('eclat_coup'), getText('autbronzant')
             ],
             "brief": getText('brief_eclat'),
             "multichoices": true,
             "widget": "multiChoices"
         }
     ],
     "_AFTER": [
         {
             "id": 15,
             "question": getText('desire_product'),
             "choices": [
                 getText('vegan'), getText('bio'), getText('natural'), getText('made_in_france')
             ],
             "brief": getText('brief_desire_product'),
             "multichoices": true,
             "widget": "multiChoices"
         },
         {
             "id": 16,
             "question": getText('renseigne_email')
         },
         {
             "id": 17,
             "widget": "offers",
             "question": getText('your_products')
         }
     ]
 }
