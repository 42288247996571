import React, { useEffect } from 'react'
import { Avatar } from '@mui/material'
import './myAvatar.css'


const MyAvatar = () => {
    let myApp = 'jess';

    return (
        <Avatar
        alt="bot"
        src={"/assets/images/profil-" + myApp + ".png"}
        sx={{ width: 56, height: 56 }}
      />
    )
}

export default MyAvatar
